import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';

export const AuthDispatchContext = createContext()
export const AuthStateContext = createContext()

const initialState = {
    user: JSON.parse(window.localStorage.getItem('user'))|| null,
    token: window.localStorage.getItem('token') || null,
    error: null
    }

const authReducer = (draft, action) => {
    switch(action.type){
        case 'LOGIN_SUCCESSFUL':{
            const { user } = action.payload
            window.localStorage.setItem('token', user.token)
            window.localStorage.setItem('user', JSON.stringify(user))
            draft.token = user.token
            draft.user = user
            return
        }
        case 'LOGIN_FAILURE':{
            draft.error = action.payload.error
            return
        }
        case 'LOGOUT':{
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('user')
            draft.token = null
            draft.user = null
            return
        }
        default:{
            return
        }
    }
}


export const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useImmerReducer(authReducer, initialState);

    return (
        <AuthDispatchContext.Provider value = { dispatch }>
            <AuthStateContext.Provider value = { state }>
                {children}
            </AuthStateContext.Provider>
        </AuthDispatchContext.Provider>
    )
}