import React from "react"
import { Root } from "./routes/Routers"
import { AuthContextProvider } from './providers/auth'
import "./styles/app.css"
import { BrowserRouter as Router } from "react-router-dom"
export default () => (
  <AuthContextProvider>
    <Router>
      <Root />
    </Router>
  </AuthContextProvider>
)
