import React from "react";
// import { getAuthUrl, generateTokens, verifyIdToken } from "../libs/authLib"
import AppleLogin from "react-apple-login";
import dots from "../assets/images/dot-bg.svg";
import coachHeader from "../assets/images/Frame.png";

const SignIn = () => {
  return (
    <div
      className="flex flex-col w-full h-full h-screen bg-cover bg-center items-center justify-center"
      style={{ background: `url(${dots})` }}
    >
      <img
        src={coachHeader}
        alt={"..."}
        style={{
          height: "auto",
          width: "65%",
          maxWidth: "375px",
          marginBottom: "2rem",
        }}
      />
      <div className="mx-4">
        <AppleLogin
          clientId={process.env.REACT_APP_APPLE_SERVICE_ID}
          redirectURI={`${process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URI}`}
          render={(props) => {
            return (
              <button
                className={
                  "relative max-w-sm mb-2 w-full mt-4 text-center flex items-center justify-center text-sm md:text-base mx-auto font-bold space-x-2 px-8 py-2 text-black rounded-full lg:px-6 bg-white border border-neutral-500/50 shadow-md"
                }
                {...props}
              >
                <img
                  src="https://media.teams.joinladder.com/website/icons/social/apple.svg"
                  alt="apple logo"
                  className="w-4 h-4 text-black"
                  width={16}
                  height={16}
                />
                <span>{"Sign In With Apple"}</span>
              </button>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SignIn;
