import React, {useEffect} from 'react'
const API_BASE = process.env.REACT_APP_API_BASE
const axios = require('axios')

export const useAPI = (url, { method = 'get', body = null} = {}) => {
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    const headers = {}

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await fetch(`${API_BASE}${url}`, 
            {
              method,
              headers,
              body
            });
          const json = await res.json();
          setResponse(json);
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    },[url,method,body,headers]);

    return { response, error };
  };

  //axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_BASE_URI
  export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE
  })
