import React, { useEffect, useContext } from "react"
import { AuthDispatchContext } from '../providers/auth'
import { api } from '../providers/api'
import useQuery from '../providers/useQuery'
import { withRouter, useHistory } from "react-router-dom"

import { routes } from "../routes/routes"

const LoginCallback = () => {
  const history = useHistory()
  const dispatch = useContext(AuthDispatchContext)
  const query = useQuery()

  const code = query.get('code')
  const redirectUri = process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URI
  if(!code)history.push(routes.SIGN_IN)

  useEffect(() => {
    api.post(`/auth/apple/code`,{code, redirectUri}).then(response => {
      const { data: user } = response
      dispatch({type:'LOGIN_SUCCESSFUL', payload: { user } })
      history.push(routes.HOME)
    }).catch(error => {
      dispatch({type:'LOGIN_FAILURE', payload: {error} })
      history.push(routes.SIGN_IN)
    })
    }, [code])

  return (
    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
      <span
        className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
        style={{
          top: "50%",
        }}
      >
        <i className="fas fa-circle-notch fa-spin fa-5x"></i>
      </span>
    </div>
  )
}

export default withRouter(LoginCallback)
