import React, { useEffect, useContext } from "react"
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from "react-router-dom"
import { AuthStateContext } from '../providers/auth'
import { routes } from "./routes"
import LoginCallback from "../pages/LoginCallback"
import SignIn from "../pages/SignIn"

export const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(route) => <route.component {...route} />}
    />
  )
}

const Root = () => {
  const AppView = () => {
    const {token, user} = useContext(AuthStateContext)
    const { path } = useRouteMatch()
    useEffect(() => {
      if (user && path === routes.HOME) {
        window.location.href = `${process.env.REACT_APP_API_BASE}/stripe/dashboard/${user.id}`
      }
    }, [user])

    return token ? (
      <div className="h-screen flex overflow-hidden bg-gray-100">
      </div>
    ) : (
      <Redirect to={{ pathname: routes.SIGN_IN }} />
    )
  }

  return (
    <Switch>
      <Route path={routes.SIGN_IN} component={SignIn} />
      <Route path={routes.CALLBACK} component={LoginCallback} />
      <Route path={routes.HOME} component={AppView} />
    </Switch>
  )
}

export { Root }
